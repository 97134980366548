.text-danger-light {
  color: #ff5370;
}

.switch-green {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  outline: none;
  margin: 0;
}

.switch-green input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: rgb(46, 202, 69);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(46, 202, 69);
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.modal-80w {
  width: 80%;
  max-width: none !important;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.bg-success-table {
  background: #deefd7 !important;
}

.bg-danger-table {
  background: #f1dddd !important;
}

.bg-info-table {
  background: #d8ecf6 !important;
}

.bg-warning-table {
  background: #fbf7e3 !important;
}

.chevron{
  display: inline-block;
  cursor: pointer;
}

.chevron svg{
  height: 18px;
  vertical-align: sub;
}

.auth-admin span:hover {
  color: #505050;
}
.auth-admin span {
  margin-left: 20px;
  margin-right: 20px;
  color: #b2b2b2;
  text-decoration: underline;
  opacity: 0.65;
  cursor: pointer;
}

.pointer{
  cursor:pointer;
}

.gap-2{
  gap: 10px;
}

.page-item.active .page-link{
  background-color: #8a8f95;
  border-color: #8a8f95;
}

.page-link{
  color: #8a8f95;
}

.page-link:hover{
  color: #fff;
  background-color: #dbdbdb;
}

.page-link:focus{
  outline: none;
  box-shadow: none;
}

.compactLogo img {
  width: 85%;
}

.compactLogo a {
  display: flex;
  justify-content: center;
}

.finance h2 {
  font-size: 2.1rem;
}

.btn{
  height: 38px;
}

.react-confirm-alert-overlay{
  z-index: 9999!important;
}

.btn-link{
  height: inherit;
}